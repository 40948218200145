.App {
  text-align: center;
}

body {
  font-family: "Lucida Console", Courier, monospace;
}

.App-header {
  min-height: 96vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2vh;
  font-size: calc(10px + 1vmin);
}

.footer-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

a {
  padding: 3px;
}

a.active {
  background-color: paleturquoise;
  color: peru;
}


@keyframes scan { 
  0% {
    left: 100%;
    bottom: 5%;   
  }

  100% { 
      left: 0%;
      bottom: 5%;   
  } 
}

@keyframes fady { 
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% { 
      opacity: 0;
  } 
}